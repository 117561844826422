import { PlatformApplication } from '../models/platform.models';

export const AppShellApplications: PlatformApplication[] = [
  {
    key: 'profile',
    enabledByRight: ['core-user-management-list-users'],
    routePath: 'profile',
  },

  // -- Assistance
  {
    key: 'storage',
    enabledByRight: ['storage-show-storage'],
    routePath: 'storage',
  },
  {
    key: 'maintenance',
    enabledByRight: ['maintenance-use-maintenance'],
    routePath: 'maintenance',
  },
  {
    key: 'time2lubrify',
    enabledByRight: ['maintenance-use-time2lubrify'],
    routePath: 'time2lubrify',
  },
  {
    key: 'import',
    enabledByRight: ['import-use-import'],
    routePath: 'import',
  },

  // -- Analytics
  {
    key: 'performance',
    enabledByRight: ['use-performance'],
    text: 'Advanced Analytics',
    routePath: 'advanced-analytics-performance',
  },
  // -- update this right!!!
  {
    key: 'ebi',
    enabledByRight: ['use-performance'],
    text: 'EBI Reports',
    routePath: 'ebi',
  },
  {
    key: 'integrated-analytics',
    enabledByRight: ['use-comparative-reporting'],
    text: 'Integrated Analytics',
    routePath: 'integrated-analytics',
  },
  {
    key: 'energy-and-media-analytics',
    enabledByRight: ['use-energy-media'],
    text: 'Energy and Media',
    routePath: 'energy-and-media-analytics',
  },
  {
    key: 'track-and-trace',
    enabledByRight: ['use-track-and-trace'],
    text: 'Track and Trace',
    routePath: 'track-and-trace',
  },
  {
    key: 'sap-hana',
    enabledByRight: ['use-s4-cloud'],
    icon: 'performance',
    text: 'S4 Cloud',
    routePath: 'sap-hana',
  },

  // -- Shopfloor Guidance
  {
    key: 'task-service',
    enabledByRight: ['use-task-service'],
    text: 'Tasks',
    routePath: 'task-service',
  },
  {
    key: 'v2-watchdog',
    enabledByRight: ['v2-watchdog-view'],
    text: 'Watchdog',
    routePath: 'v2-watchdog',
  },

  // -- Optimization
  {
    key: 'contiloop-ai',
    enabledByRight: ['show-contiloop-service'],
    text: 'Contiloop AI',
    routePath: 'contiloop-ai',
  },

  // -- LineControl
  {
    key: 'lms',
    enabledByRight: ['use-erp-analytics'],
    text: 'Order Management',
    routePath: 'lms',
  },

  // -- CoreService
  {
    key: 'user-management',
    enabledByRight: ['core-user-management-list-users'],
    routePath: 'user-management',
  },
  {
    key: 'equipment',
    enabledByRight: ['core-equipment-modify'],
    routePath: 'equipment',
  },
  {
    key: 'external-api',
    enabledByRight: ['external-api'],
  },
  {
    key: 'shift-management',
    enabledByRight: ['show-shift-management'],
    text: 'Shift Management',
  },
  {
    key: 'reason-code',
    enabledByRight: ['reason-code-available'],
    text: 'Reason Code',
  },
  {
    key: 'connect',
    enabledByRight: ['read-timeline'],
    text: 'Connect',
    routePath: 'connect',
  },

  // -- Legacy
  {
    key: 'demo-trigger',
    enabledByRight: ['demo-use-demo'],
  },
  {
    key: 'site-service',
    enabledByRight: ['site-service-use-site-service'],
  },
];
