import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AppRegistry } from '../models/app-registry.model';
import { SideNavGroupItem } from '../models/side-nav-group-item.model';
import { SidenavItem } from '../models/sidenav-item.model';
import { PageLoaderService } from './pageLoader.service';

export enum ItemSection {
  S2a = 0,
  Core = 1,
  External = 2,
  Action = 3,
  ANALYTICS = 4,
  SHOPFLOOR_GUIDANCE = 5,
  OPTIMIZATION = 6,
  LINE_CONTROL = 7,
  ASSISTANCE = 8,
}

@Injectable({
  providedIn: 'root',
})
export class AppRegistryService {
  constructor(private pageLoaderService: PageLoaderService) {}
  get sidenavItems(): SideNavGroupItem[] {
    return [
      this.getAssistanceItems(),
      this.getAnalyticsItems(),
      this.getShopFloorGuidanceItems(),
      this.getOptimizationItems(),
      this.getLineControlItems(),
      this.getCoreItems(),
      this.getExternalItems(),
      this.getActionItems(),
    ];
  }

  get appBell(): SidenavItem {
    let item: SidenavItem;
    const app = this.pageLoaderService.components.find((component: AppRegistry) => component.id === 'app-bell');
    if (app) {
      item = {
        id: app.id,
        textKey: `apps.${app.key ?? 'notset'}`,
        icon: app.icon ?? 'notset',
        canOpenInNewWindow: false,
        section: ItemSection.Action,
      };
    }

    return item;
  }

  get customerSupport(): SidenavItem {
    let item: SidenavItem;
    const app = this.pageLoaderService.components.find((component: AppRegistry) => component.id === 'customer-support');
    if (app) {
      item = {
        id: app.id,
        textKey: `components.platform_header.customer_support`,
        icon: app.icon ?? 'not set',
        canOpenInNewWindow: false,
        section: ItemSection.Action,
      };
    }

    return item;
  }

  private getAssistanceItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];
    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isAssitanceItems(app.key)) {
        items.push({
          id: app.id,
          textKey: `apps.${app.key}`,
          icon: app.icon,
          canOpenInNewWindow: true,
          section: ItemSection.ASSISTANCE,
          routePath: app.routePath,
        });
      }
    });

    return {
      groupName: 'Assistance',
      displayLocaleText: true,
      items,
    };
  }

  private getAnalyticsItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isAnalyticsItem(app.routePath)) {
        items.push({
          id: app.id,
          textKey: ``,
          icon: 'performance',
          canOpenInNewWindow: true,
          text: app.text,
          section: ItemSection.ANALYTICS,
          routePath: app.routePath,
        });
      }
    });

    return {
      groupName: 'Analytics',
      displayLocaleText: false,
      items,
    };
  }

  private getShopFloorGuidanceItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isShopfloorGuidanceItems(app.routePath)) {
        items.push({
          id: app.id,
          textKey: `apps.${app.key}`,
          icon: app.icon,
          canOpenInNewWindow: true,
          section: ItemSection.SHOPFLOOR_GUIDANCE,
          routePath: app.routePath,
        });
      }
    });

    return {
      groupName: 'Shopfloor Guidance',
      displayLocaleText: true,
      items,
    };
  }

  private getOptimizationItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isOptimizationItems(app.key)) {
        items.push({
          id: app.id,
          textKey: ``,
          icon: app.icon,
          canOpenInNewWindow: true,
          text: app.text,
          section: ItemSection.OPTIMIZATION,
          routePath: app.routePath,
        });
      }
    });

    return {
      groupName: 'Optimization',
      displayLocaleText: false,
      items,
    };
  }

  private getLineControlItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isLineControlItem(app.routePath)) {
        items.push({
          id: app.id,
          textKey: ``,
          icon: 'equipment_line',
          canOpenInNewWindow: true,
          text: app.text,
          section: ItemSection.LINE_CONTROL,
          routePath: app.routePath,
        });
      }
    });

    return {
      groupName: 'Line Control',
      displayLocaleText: false,
      items,
    };
  }

  private getCoreItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];
    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isCoreService(app.key)) {
        items.push({
          id: app.id,
          textKey: `apps.${app.key}`,
          icon: app.icon,
          canOpenInNewWindow: true,
          section: ItemSection.Core,
          routePath: app.routePath,
        });
      }
    });

    return {
      groupName: '',
      displayLocaleText: true,
      items,
    };
  }

  private getExternalItems(): SideNavGroupItem {
    return {
      groupName: '',
      displayLocaleText: true,
      items: [
        {
          id: 'sessions',
          textKey: 'apps.sessions',
          icon: 'sessions',
          canOpenInNewWindow: false,
          section: ItemSection.External,
        },
        // {
        //   id: 'krones-shop',
        //   textKey: 'components.platform_header.krones_eshop',
        //   icon: 'storage_cart',
        //   canOpenInNewWindow: false,
        //   section: ItemSection.External,
        // },
      ],
    };
  }

  private getActionItems(): SideNavGroupItem {
    const sideBarActionItems = [
      {
        id: 'toggle-theme',
        textKey: 'components.platform_header.toggle_theme',
        icon: 'toggleTheme',
        canOpenInNewWindow: false,
        section: ItemSection.Action,
      },
      {
        id: 'language-selection',
        textKey: 'components.platform_header.change_language',
        icon: 'language',
        canOpenInNewWindow: false,
        section: ItemSection.Action,
      },
      {
        id: 'logout',
        textKey: 'components.platform_header.logout',
        icon: 'logout',
        canOpenInNewWindow: false,
        section: ItemSection.Action,
      },
    ];
    // insert the customer support item in correct order
    const customerSupport = this.customerSupport;
    if (customerSupport) {
      sideBarActionItems.splice(1, 0, customerSupport);
    }

    const filteredSideBarActionItems = !environment.enableSupportRequest
      ? sideBarActionItems.filter((item: SidenavItem) => item.id !== 'customer-support')
      : sideBarActionItems;

    return {
      groupName: '',
      displayLocaleText: true,
      items: filteredSideBarActionItems,
    };
  }

  private isAssitanceItems(routePath: string): boolean {
    const groupItems = [
      'storage', // Assistance SP
      'maintenance', // Assistance MM
      'time2lubrify', //
      'import', // Import Service
    ];

    return groupItems.includes(routePath);
  }

  private isAnalyticsItem(routePath: string): boolean {
    const groupItems = [
      'advanced-analytics-performance', // Performance
      'integrated-analytics', // Integrated Analytics
      'sap-hana', // S4 Cloud
      'energy-and-media-analytics',
      'track-and-trace', // track-and-trace
      'ebi',
    ];

    return groupItems.includes(routePath);
  }

  private isLineControlItem(routePath: string): boolean {
    const groupItems = [];

    return groupItems.includes(routePath);
  }

  private isShopfloorGuidanceItems(routePath: string): boolean {
    const groupItems = [
      'task-service', // Tasks
      'v2-watchdog', // Watchdog
    ];

    return groupItems.includes(routePath);
  }

  private isOptimizationItems(appId: string): boolean {
    const groupItems = [
      'contiloop-ai', // Contiloop AI
    ];

    return groupItems.includes(appId);
  }

  private isCoreService(appId: string): boolean {
    const groupItems = [
      'user-management', // User Management
      'equipment', // Equipment
      'external-api', // API Documentation
      'shift-management', // Shift Management
      'reason-code', // Reason Codes
      'connect', // Connect
    ];

    return groupItems.includes(appId);
  }
}
